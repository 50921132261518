import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { promise } from 'protractor';
import { CommonService } from '../shared-service/common.service';
import { GroupSizeService } from './group-size.service';

@Component({
  selector: 'app-group-size',
  templateUrl: './group-size.component.html',
  styleUrls: ['./group-size.component.scss']

})

export class GroupSizeComponent implements OnInit {
  visitor: any = 1;
  phone: any;
  help_txt_1: any;
  help_txt_2: any;
  lastnum: any;
  size: any;
  numList: any[] = [];
  hiddendiv: boolean = false;
  sanLocation: boolean = false;
  uniqueKey: any;
  grpsizeResponse: any;
  plantDetails: any;
  prePlantid: any;
  preUniquekey: any;
  placename: any;
  plantdata: any = [];
  uniquenumber: any;
  uniquekey: any;
  showeditReservation: string = "false";
  queryParams: any;
  plantId: any;
  showspinner = false;

  constructor(private route: ActivatedRoute, private readonly router: Router,
    public readonly appService: CommonService,
    private grpService: GroupSizeService) {

    // this.route.queryParams.subscribe(params => {
    //   this.queryParams = params;
    //   console.log('this.queryParams: ', this.queryParams);
    //   this.plantId = this.queryParams['plantId'];
    //   console.log('this.projId: ', this.plantId);
    // });

    // let qp = this.route.queryParams.toPromise().then(param => { return param });
    // console.log('qo', qp);
    // this.plantId = +qp['plantId'];
  }

  async fetchqueryParam() {
    console.log('in query paramns');
    let plantId = 0;
    let qp = await this.route.queryParams.subscribe(async param => {
      console.log('qo', qp);
      let value = await param;
      plantId = +value['plantId'];
      console.log('plantId: ', plantId);

    });
    return plantId;


    // this.route.queryParams.subscribe(params => {
    //   this.queryParams = params;
    //   console.log('this.queryParams: ', this.queryParams);
    //   this.plantId = this.queryParams['plantId'];
    //   console.log('this.projId: ', this.plantId);
    // });

  }



  async ngOnInit() {
    // let url = window.location.href
    // let data = url.split('/').reverse()[0]
    // console.log("data",Number(data));
    if (localStorage.getItem("viewReserFlag") != "view") {
      localStorage.clear();
      console.log("inside loaclstorgae clear");

    }
    this.plantId = await this.fetchqueryParam();
    if (this.plantId != 5) {
      this.router.navigate(['/home']);
      return;
    }
    if (this.plantId) {
      let a: any = await this.getPlantDetail(this.plantId)
      if (a) {

        ////from mail
        if (localStorage.getItem("viewReserFlag") == "view" && localStorage.getItem("uniqkeyFromReservation") != null) {
          let details = JSON.parse(localStorage.getItem('resvResponse'))
          this.uniqueKey = localStorage.getItem("uniqkeyFromReservation")
          console.log("details", details);
          if (details != null) {
            let plantId = details[0].toy_tour_plnt_id
            this.visitor = details[0].grp_size
            let a = await this.getTokengrpSize();
            if (a) {
              this.grpService.gettourPlan().subscribe((response: any) => {
                console.log("plant information in groupsize component", response.Toyotatour_Plant.response);
                this.plantDetails = response.Toyotatour_Plant.response
                localStorage.setItem("plantDetailsfromView", JSON.stringify(this.plantDetails))
                for (let i = 0; i < this.plantDetails.length; i++) {
                  if (plantId == this.plantDetails[i].toy_tour_plnt_id) {
                    localStorage.setItem("location", JSON.stringify(this.plantDetails[i]))
                    this.appService.location = JSON.parse(localStorage.getItem('location'))
                    console.log("this.appService.location in If", this.appService.location);
                    this.getdropdownvalue()
                  }
                }
              })
            }

          }
          console.log("this.visitor", this.visitor);

        }
        else {
          this.appService.location = JSON.parse(localStorage.getItem('location'));
          this.getdropdownvalue();
          this.uniqueKey = localStorage.getItem('uniquekey');
        }

        if (localStorage.getItem('groupSizeValue') != undefined) {
          this.showspinner = true;
          this.visitor = Number(localStorage.getItem('groupSizeValue'));
        }
      }
    }

  }


  navigateToVerification() {
    if (this.visitor != '') {
      localStorage.setItem('groupSizeValue', this.visitor);
      this.insertTempGrp();
      this.router.navigateByUrl('/age-verification');

    }
  }

  getdropdownvalue() {
    this.showspinner = true;
    this.phone = this.appService.location.plnt_phno;
    if (this.phone != undefined) {
      this.phone = this.phone.replace(/(\d{1})(\d{3})(\d{3})(\d)/, '$1-$2-$3-$4');
    }
    this.help_txt_1 = this.appService.location.help_txt_1;
    this.help_txt_2 = this.appService.location.help_txt_2;
    this.size = Number(this.appService.location.max_grp_size);

    for (let i = 1; i <= this.size; i++) {
      this.numList.push(i);
      this.showspinner = false;
    }
    let last = this.appService.location.abv_max_grp_size_flg;
    if (last == 'Y') {
      let no = this.size + 1;
      this.numList.push(no.toString() + "+");
      this.lastnum = this.numList[this.numList.length - 1];
      this.showspinner = false;
    }
    if (this.appService.location.plnt_city == "San Antonio") {
      this.sanLocation = true;
    }

  }

  changeGrpSizeValue() {
    if (this.lastnum != undefined && this.visitor == this.lastnum.toString()) {
      this.hiddendiv = true;
    }
    else {
      this.hiddendiv = false;
    }
  }

  async insertTempGrp() {
    let a = await this.getTokengrpSize()
    if (a) {
      let tempDetails = {
        "uniqueKey": this.uniqueKey,
        "grpSize": this.visitor,
        "plantid": this.appService.location.toy_tour_plnt_id
      }
      this.grpService.insertTempGrpInfo(tempDetails).subscribe((response: any) => {
        console.log('response', response.Tmp_Grp_Info_Insert.response);
        this.grpsizeResponse = response.Tmp_Grp_Info_Insert.response;
        if (this.grpsizeResponse != "Success!!!!") {
          if (this.grpsizeResponse != null) {
            this.grpsizeResponse = this.grpsizeResponse[0].toy_tour_tmp_grp_info_id
          }
          localStorage.setItem('grpsizeResponse', JSON.stringify(this.grpsizeResponse));
        }
      })
    }
  }

  getTokengrpSize() {
    return new Promise(resolve => {
      this.grpService.generateToken().subscribe((response: any) => {
        console.log("token response", response.access_token);
        let grpsizeToken = response.access_token
        localStorage.setItem('grpsizeToken', grpsizeToken)
        resolve(true)
      })
    })
  }




  async getPlantDetail(data) {
    return new Promise(async resolve => {
      let a = await this.getToken();
      if (a) {
        let plantdata = this.plantdata;
        console.log("plantdata", plantdata);

        for (let i = 0; i < plantdata.length; i++) {
          if (plantdata[i].toy_tour_plnt_id == data) {
            localStorage.setItem("location", JSON.stringify(plantdata[i]))
          }
        }
        this.navigatetoGroupsize();
        resolve(true)
      }
    })

  }


  async getToken() {
    console.log("inside gettoken");
    this.showspinner = true;
    return new Promise(resolve => {
      this.grpService.generateToken().subscribe(async (response: any) => {
        if (response) {
          console.log("token response", response.access_token);
          let homeToken = response.access_token
          localStorage.setItem('homeToken', homeToken)
          console.log("hometoken", localStorage.getItem('homeToken'));
          let a = await this.getplants();
          if (a) {
            resolve(true)
            this.showspinner = false;
          }
        }

      })
    })
  }

  getplants() {
    this.showspinner = true;
    return new Promise(resolve => {
      this.grpService.gettourPlan().subscribe((response: any) => {
        console.log("response", response);
        this.plantdata = response.Toyotatour_Plant.response;
        this.showspinner = false;
        console.log(this.plantdata);
        resolve(true)
      })
    })
  }


  navigatetoGroupsize() {
    this.preUniquekey = localStorage.getItem('preUniquekey')
    this.prePlantid = localStorage.getItem('prePlantid')
    let detail = JSON.parse(localStorage.getItem('location'))
    if (detail != null) {
      this.placename = detail
      console.log("placename", this.placename);
    }
    if (this.placename != '') {

      this.appService.location = this.placename;
      console.log("this.appService.location", this.appService.location);

      localStorage.setItem("location", JSON.stringify(this.appService.location));
      this.uniquenumber = this.uniqueNumberGenerator()
      let date = new Date();
      this.uniquekey = this.placename.plnt_state + this.uniquenumber + date.getFullYear() + (date.getMonth() + 1) + date.getDate() + date.getHours()
        + date.getMinutes() + date.getSeconds()
      console.log('this.appService.location.toy_tour_plnt_id', this.appService.location.toy_tour_plnt_id, this.prePlantid, this.preUniquekey)
      if (this.appService.location.toy_tour_plnt_id == this.prePlantid && this.preUniquekey != null) {
        this.showeditReservation = "true";
        localStorage.setItem('showedit', this.showeditReservation)
        this.appService.uniquekey = this.preUniquekey
        localStorage.setItem("preUniquekey", this.preUniquekey);
      }

      else {
        this.appService.uniquekey = this.uniquekey
        localStorage.setItem("uniquekey", this.uniquekey);
      }


      // this.router.navigateByUrl('/group-size');
    }
  }

  uniqueNumberGenerator() {
    return 'xxxxxxyxyx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16)
    });

  }


}
